import { useQuery } from '@apollo/client'
import { Autocomplete } from '@mui/material'
import React, {useMemo, useState, useEffect, useCallback} from 'react'
import { getEmployees } from './EmployeesInputGraphQL'
import { useFormikContext } from 'formik'
import useStyle, {
  BottomInputData,
  ErrorContainer,
  Input,
  InputContainer,
  SwitchContainer,
} from './EmployeesInput.style'
import CloseIcon from '@mui/icons-material/Close'
import { SwitchField } from 'front-components/dist/cjs'

const EmployeesInput = ({ clearEmployeeInput }) => {
  const [canWriteCustomName, setCanWriteCustomName] = useState(false)
  const classes = useStyle()

  const { setFieldValue, errors, touched, values } = useFormikContext()

  const value = values.for_who

  const isEmptyArray = Array.isArray(value) && value.length === 0
  const isError = (errors.for_who || isEmptyArray) && touched.for_who
  const isCharsLimitExceded = canWriteCustomName && value.length > 125

  const { data } = useQuery(getEmployees)

  const handleClearInputValue = useCallback(() => {
    setFieldValue('inputValue', '')
  }, [setFieldValue])

  useEffect(() => {
    handleClearInputValue()
  }, [value, handleClearInputValue])


  const getErrorMessage = () => {
    if (isCharsLimitExceded) {
      return 'Pole nie może mieć więcej niż 125 znaków!'
    }

    if (isEmptyArray) {
      return 'To pole jest wymagane!'
    }

    return errors.for_who
  }

  const formattedEmployees = useMemo(
    () => [
      { id: 'all', label: 'Zaznacz wszystkich' },
      ...(data?.users.data.map(({ id, lastname, firstname }) => ({
        id,
        label: `${firstname} ${lastname}`,
      })) || []),
    ],
    [data],
  )

  return (
    <InputContainer>
      <Autocomplete
        disablePortal
        id='employees'
        multiple={!canWriteCustomName}
        value={value}
        ChipProps={{ deleteIcon: <CloseIcon /> }}
        variant='standard'
        className={classes.input}
        disableClearable
        options={canWriteCustomName ? [] : formattedEmployees}
        autoComplete={false}
        freeSolo={canWriteCustomName}
        onChange={(_, newValue) => {
            const isAllSelected = newValue?.some((obj) => obj.id === 'all')

            if (isAllSelected) {
                setFieldValue('for_who', formattedEmployees.slice(1))
            } else {
                setFieldValue('for_who', newValue)
            }
        }}
        onInputChange={(event, newInputValue) => {
            setFieldValue('inputValue', newInputValue)
        }}
        onKeyDown={(event) => {
            if (event.key === 'Tab' && !canWriteCustomName) {
                const filteredEmployees = formattedEmployees.filter(employee =>
                    employee.label.toLowerCase().includes(values.inputValue.toLowerCase()) &&
                    !value.some(v => v.id === employee.id) &&
                    employee.id !== 'all'
                )
                if (filteredEmployees.length > 0) {
                    event.preventDefault()
                    const selectedEmployee = filteredEmployees[0]
                    setFieldValue('for_who', [...value, selectedEmployee])
                    setFieldValue('inputValue', '')
                }
            }
        }}
        sx={{ width: '100%' }}
        renderInput={(params) => (
          <Input
            isError={isError}
            onChange={({ target }) => {
              if (canWriteCustomName) {
                setFieldValue('for_who', target.value)
              }
            }}
            variant='standard'
            inputProps={{
              maxLength: 100,
            }}
            name='for_who'
            {...params}
            label='Dla'
          />
        )}
      />

      {(isError || isCharsLimitExceded) && (
        <ErrorContainer>{getErrorMessage()}</ErrorContainer>
      )}

      <BottomInputData>
        {!canWriteCustomName && <p>Możesz wybrać kilka osób</p>}

        <SwitchContainer>
          <SwitchField
            onChange={() => {
              setCanWriteCustomName((prevState) => {
                if (!prevState) {
                  setFieldValue('for_who', '')
                } else {
                  setFieldValue('for_who', [])
                }

                return !prevState
              })
            }}
          />
          <p>Chcę wpisać samodzielnie odbiorcę</p>
        </SwitchContainer>
      </BottomInputData>
    </InputContainer>
  )
}

export default EmployeesInput
